import React, { useContext } from "react";
import styles from './AccountLoginOrRegister.module.css';
import { UserContext } from "../../context/userContext";

const AccountLoginOrRegister = (props) => {
  
  const userContext = useContext(UserContext);
  const { logInWithEmailAndPassword  , registerWithEmailAndPassword} = userContext;

  // this will handle to user Login

  const handleLogInClick = () => {
    const email = document.getElementById("email").value
    const password = document.getElementById("password").value
    if (email && password){
      logInWithEmailAndPassword(email,password)
    }
    else {
      document.getElementById("errorText").innerText = 'Enter Details'
    }
  }

  // this will handle to user regsitering 
  const handleRegisterClick = () => {
    const name = document.getElementById("name").value
    const email = document.getElementById("email").value
    const password = document.getElementById("password").value
    if (email && password){
      registerWithEmailAndPassword(name,email,password)
    }
    else {
      document.getElementById("errorText").innerText = 'Enter Details'
    }
  }
    // this will handle to user resetting 
    //  this is not working due to needing a mailbox (i think)

  // const handleResetClick = () => {
  //   const email = document.getElementById("email").value
  //   if (email){
  //     sendPasswordReset(email)
  //   }
  //   else {
  //     document.getElementById("errorText").innerText = 'Enter Details'
  //   }
  // }

  return (
    <div className={styles.AccountLoginOrRegister}>
      <h2>Account</h2>
      <h3>Login/Register</h3>
      <p id="errorText" className={styles.errorText}></p>
      <input id='name' type="text" placeholder="name"/>
      <input id='email' type="email" placeholder="email"/>
      <input id='password' type="password" placeholder="password"/>
      <button id='loginButton' onClick={handleLogInClick}>Login</button>
      <button id='registerButton' onClick={handleRegisterClick}>Register</button>
    </div>
  );
};

export default AccountLoginOrRegister;
