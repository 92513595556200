import React from 'react'
import styles from './Modal.module.css'

export const Modal = (props) => {
  return (
        <div className={styles.Modal}>
          <div className={styles.Loader}>
          </div>
        </div>
  )
}
export default Modal
