import React, { createContext, useEffect, useState } from "react";
import {Buffer} from 'buffer';

export const PaymentContext = createContext({});

export const PaymentProvider = (props) => {
  const [accessToken, setAccessToken] = useState(null);
  const [clientToken, setClientToken ] = useState(null);

  useEffect(() => {
    generateAccessToken()
    if (accessToken && !clientToken) {
        generateClientToken(accessToken)
      }
    });

// call this function to create your client token from PALPAL auth apI
async function generateClientToken() {
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/identity/generate-token`, {
      method: "post",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Accept-Language": "en_US",
        "Content-Type": "application/json",
      },
    });
    const data = await response.json();
    setClientToken(data.client_token)
}
// access token is used to get the Acess totken from PALPAL auth apI
async function generateAccessToken() {
    const auth = Buffer.from(process.env.REACT_APP_CLIENT_ID + ":" + process.env.REACT_APP_APP_SECRET).toString("base64");
    const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v1/oauth2/token`, {
      method: "post",
      body: "grant_type=client_credentials",
      headers: {
        Authorization: `Basic ${auth}`,
      },
    });
    const data = await response.json();
    setAccessToken(data.access_token)
}

// call this function to get the order Status from PAYPAL
async function getOrderStatus(orderID) {
  const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/v2/checkout/orders/${orderID}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Accept": "*/*",
      "Accept-Encoding": "gzip, deflate, br",
      "Connection" : "keep-alive"
    },
  });
  const data = await response.json();
  return data
}

// call this function to refund and update Status
async function getRefund(href) {
  const response = await fetch(`${href}`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "application/json"
    },
  }).catch((err) => {
    console.log(err)
  })
  const data = await response.json();
  return data
}
// call this function to make paypal order

function createPaypalOrder(checkoutBasketObject, setIsLoading ,handleErrors ) {
  return fetch(`${process.env.REACT_APP_API_ENDPOINT}/v2/checkout/orders/`, {
    method: 'post',
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${accessToken}`
    },    
    // use the "body" param to optionally pass additional order information like
    // product ids or amount.
    body: JSON.stringify(
      {
        "intent": "CAPTURE",
        "purchase_units": [checkoutBasketObject],
        "application_context": {
            "return_url": "http://localhost:3000/",
            "cancel_url": "https://example.com/cancel"
        }
    })
  })
  .catch((err) => {
    console.log(err)
  }).then((res) => res.json())
  .then((orderData) => {
    return orderData.id
  })
}
// call this function to complete paypal order

  async function completePaypalOrder( 
    setIsLoading, handleErrors, addOrderToMyDataBase, checkoutBasketObject, cardholderName
    , billingAddress, shipping, email, orderId , fetchOrders, user){

      setIsLoading(true)
    return fetch(`${process.env.REACT_APP_API_ENDPOINT}/v2/checkout/orders/${orderId}/capture`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }).catch((err) => {
      handleErrors(err.details[0].description, "errorTextPayments")
    })
      .then((res) => res.json())
      .then((orderData) => {
        setIsLoading(false)
        // Three cases to handle:
        //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
        //   (2) Other non-recoverable errors -> Show a failure message
        //   (3) Successful transaction -> Show confirmation or thank you
        // This example reads a v2/checkout/orders capture response, propagated from the server
        // You could use a different API or structure for your 'orderData'
        var errorDetail =
          Array.isArray(orderData.details) && orderData.details[0]
        if (errorDetail && errorDetail.issue === "INSTRUMENT_DECLINED") {
          //return actions.restart(); // Recoverable state, per:
          // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
        }
        if (errorDetail) {
          var msg = "Sorry, your transaction could not be processed."
          if (errorDetail.description)
            msg += "\n\n" + errorDetail.description;
          if (orderData.debug_id) msg += " (" + orderData.debug_id + ")"
          return alert(msg); // Show a failure message
        }
        addOrderToMyDataBase( orderId
                            , orderData
                            , orderData.status
                            , cardholderName
                            , billingAddress
                            , checkoutBasketObject
                            , "PAID" 
                            , shipping
                            , email
                            , process.env.REACT_APP_ENVIRONMENT
                            )
        // Show a success message or redirect
        alert("Transaction completed!");
        //redirect
        fetchOrders(user, user.role)
       });
    };


    return (
      <PaymentContext.Provider value={{ 
                                      clientToken,
                                      createPaypalOrder,
                                      completePaypalOrder,
                                      getOrderStatus,
                                      getRefund
                                        }}>      
      {props.children}
    </PaymentContext.Provider>
  );
};