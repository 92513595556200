import React, { createContext, useEffect,useState  } from "react";
import { getStorage, ref, getDownloadURL , uploadBytesResumable} from "firebase/storage";
import { firebase } from '../firebase.js'
import { collection , doc, getDocs, updateDoc, addDoc , deleteDoc } from "firebase/firestore";

const storage = getStorage();

export const ProductContext = createContext({});

export const ProductProvider = (props) => {
  
  const [productsObject, setproductsObject] = useState([])
  const [isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    fetchProducts()
  }, [
  ]);
  //This function will get the products documents from firestore
  const fetchProducts = async () => {
    const notesSnapshot = await getDocs(collection(firebase, "products"));
    const notesList = notesSnapshot.docs.map((doc) => doc.data());
    setproductsObject(notesList)
  };
 
  //this funtion will add Products to the firestore order document collection
    const addProductToDataBase = async () => {
      await addDoc(collection(firebase, "products") , {
          id: '',
          description : "",
          imageLink : "for updating an image",
          imageURL : "For Adding Images through Links",
          name : "",
          quantity : 1,
          unit_amount : {"currency_code" : "GBP",
                        "value" : 0               
        },            
          }).catch(err => {alert(err)})
          .then(result => {
            // To update age and favorite color:
            const ordersObject = doc(firebase, "products", result.id);
            updateDoc(ordersObject, {
              id:  `${result.id}`
            });
          })
          fetchProducts()

        }
  
  //this funtion will remove Products to the firestore products document collection
  const removeProductFromDataBase = async (documentid) => {
    await deleteDoc(doc(firebase, "products", documentid))
      .catch(err => {alert(err)})
    fetchProducts()

  }
  // This will upload an image to the cloud firestore
  const uploadImageDataFromStorage = (storageReference, file, collectionToUpdate, documentToUpdate, columnToUpdate, updateData) => {
    var UploadedURL
    const storageRef = ref(storage, storageReference);
    // 'file' comes from the Blob or File API
    const uploadTask = uploadBytesResumable(storageRef, file);
    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on('state_changed', 
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        //const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        //console.log('Upload is ' + progress + '% done');
        // eslint-disable-next-line
        switch (snapshot.state) {
          case 'paused':
            break;
          case 'running':
            setIsLoading(true)
            break;
        }
      }, 
      (error) => {
        console.log(error);
      }, 
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        setIsLoading(false)
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          UploadedURL = downloadURL
          updateData(`${collectionToUpdate}`,  `${documentToUpdate}`,`${columnToUpdate}`, UploadedURL)
        });
      }
    );
    
}
  return (
    <ProductContext.Provider value={{ fetchProducts 
                                      , productsObject
                                      , addProductToDataBase
                                      , removeProductFromDataBase
                                      , uploadImageDataFromStorage
                                      , isLoading
                                       }}>
      {props.children}
    </ProductContext.Provider>
  );
};