import React, { useContext } from "react";
import styles from './Contact.module.css';
import { UserContext } from "../../context/userContext";

const Contact = () => {
  const userContext = useContext(UserContext);
  const { clientDataObject } = userContext;

    return (
      <div className={styles.Contact}>
         <h2>Contact</h2>

          <p>{clientDataObject.contactText}</p>
      </div>
    );
  }  

export default Contact;
