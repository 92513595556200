import React, { createContext , useState, useEffect, useContext } from "react";
import { firebase, auth } from '../firebase.js'
import { useAuthState  } from "react-firebase-hooks/auth";
import { signInWithEmailAndPassword, createUserWithEmailAndPassword,  sendPasswordResetEmail , signOut} from "firebase/auth";
import { collection , updateDoc ,setDoc, doc, getDocs, getDoc,  where, query, orderBy } from "firebase/firestore";
import { ProductContext } from "./productContext";
import { Navigate } from 'react-router-dom';

export const UserContext = createContext({});

export const UserProvider = (props) => {
 
  const [clientDataObject, setClientDataObject] = useState([])
  const [ordersObject, setOrdersObject] = useState([])
  const [user, loading  ] = useAuthState(auth);
  const productContext = useContext(ProductContext);
  const { fetchProducts } = productContext;

  useEffect(() => {
    fetchClientData()
    if (user) {
      fetchUserRoles(user).then(role=>{
        fetchOrders(user, role)
      })
   }
  // eslint-disable-next-line
  }, [user,loading ]);

    //this funtion will dynmically updatr the database with any value, base on the inputs

    const updateData = async (collectionToUpdate, databaseDocumentID , columnToUpdate , valueToUpdate) => {
        const clientsObject = doc(firebase, collectionToUpdate, databaseDocumentID);
        const updatedObjectValue =  { [columnToUpdate]:  `${valueToUpdate}` }
        await updateDoc(clientsObject,updatedObjectValue).catch(err => console.log(err))
        if (collectionToUpdate === 'clientInformation'){
          fetchClientData()
        } else if (collectionToUpdate === 'products'){
          fetchProducts()
        }
      } 

    // this function will update the mainifest.json values
    const updateMainifest = (clientDocumentsObj) => {
      var myDynamicManifest = {
        "name": clientDocumentsObj.nameOfCompany ,
        "short_name": clientDocumentsObj.nameOfCompany ,
        "description": clientDocumentsObj.aboutText ,
        "start_url":process.env.REACT_APP_URL,
        "background_color": "#000000",
        "theme_color": "#0f4a73",
        "icons": [{
          "src": clientDocumentsObj.iconURL,
          "sizes": "256x256",
          "type": "image/png"
        }]
      }
      const stringManifest = JSON.stringify(myDynamicManifest);
      const blob = new Blob([stringManifest], {type: 'application/json'});
      const manifestURL = URL.createObjectURL(blob);
      document.querySelector('#my-manifest-placeholder').setAttribute('href', manifestURL);

      //document.title = clientDocumentsObj.nameOfCompany;

    } 

   
    // this function willl get the clientdata 
    const fetchClientData = async () => {
        const q = query(collection(firebase, "clientInformation"));
        const clientData = await getDocs(q);
        const clientDocumentsObj = clientData.docs.map((doc) => doc.data())[0];
        updateMainifest(clientDocumentsObj)
        setClientDataObject(clientDocumentsObj)
        return clientDocumentsObj
   };

   // this function handles the errors returned and adds them to the site
    const handleErrors = (errorObject, selector) =>{
      document.getElementById(`${selector}`).innerText = errorObject
    }
    
    // this function will allow th user to login.
    const logInWithEmailAndPassword = async (email, password) => {
      try {
        await signInWithEmailAndPassword(auth, email, password);
      } catch (err) {
        console.log(err)
        handleErrors(err, "errorText")
      }
    };

    // this function will allow th user to logout
    const logout = () => {
      signOut(auth);
    };
    
    // this function will allow th user to register.
    // it wil alos add into to the user object
    const registerWithEmailAndPassword = async (name, email, password) => {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        const user = res.user;
        var currentdate = new Date(); 
        const  documentid = user.uid
        await setDoc(doc(firebase, "users", documentid), {
          uid: user.uid,
          name,
          authProvider: "local",
          email,
          role : 'user', 
          created : currentdate
        });
      } catch (err) {
        alert(err)
      }
      return (<Navigate replace to="/Account" />)
    };

    // this function will allow th user to reset their password.
    const sendPasswordReset = async (email) => {
      try {
        await sendPasswordResetEmail(auth, email);
        alert("Password reset link sent!");
      } catch (err) {
        console.error(err);
        alert(err.message);
      }
    };

    //this funtion will add orers to the firestore order document collection
    const addOrderToMyDataBase = async (orderId 
                                        , orderData
                                        , status
                                        , name 
                                        , billingAddres 
                                        , items 
                                        , orderStatus
                                        , deliveryAddress
                                        , email
                                        , environment
                                        ) => {
      await setDoc(doc(firebase, "orders", orderId), {
        uid: user.uid,
        orderId : orderId,
        orderStatus : orderStatus,
        paymentStatus : status, 
        name : name,
        billingAddres : billingAddres,
        paypalOrderData : orderData,
        boughtItems: items,
        deliveryAddress : deliveryAddress,
        email : email,
        environment : environment,
        createTime : orderData.purchase_units[0].payments.captures[0].create_time
      }).catch(err => {console.log(err)})
    }


    //this funtion will update the shipping status of the order
    const updateOrderStatus = async (databaseDocumentID , message) => {
      const ordersObject = doc(firebase, "orders", databaseDocumentID);
      await updateDoc(ordersObject, {
        orderStatus:  `${message}`
      });
      fetchOrders(user, user.role)
    } 
    
    //this funtion will update the shipping status of the order, if they are an admmin
    const updateOrderShippedStatus = async (databaseDocumentID , message, role) => {
      if (role === 'admin'){
        const ordersObject = doc(firebase, "orders", databaseDocumentID);
        await updateDoc(ordersObject, {
          orderStatus:  `${message}`
        });
        fetchOrders(user, role)
      }
    } 
    
    // this function willl get the orders for a user 
    // if they are an admin (role= admin) all orders are shown
    // otherwise just show the users orders

    const fetchOrders = async (user, role) => {
      if (user && role === 'user') {
        const q = query(collection(firebase, "orders"), where("uid", "==", user.uid) ,orderBy("createTime", "desc"));
        const OrdersDocuments = await getDocs(q);
        const OrdersDocumentsUser = OrdersDocuments.docs.map((doc) => doc.data());
        setOrdersObject(OrdersDocumentsUser)
      } 
      else if (user && role === 'admin') {
        const q = query(collection(firebase, "orders"), orderBy("createTime", "desc"));
        const OrdersDocuments = await getDocs(q);
        const OrdersDocumentsAdmin = OrdersDocuments.docs.map((doc) => doc.data());
        setOrdersObject(OrdersDocumentsAdmin)
      }
    };
      
    // this function willl get the user Data for a user, 
    // it will also update the user object with the role attribute 
    const fetchUserRoles = async (userObject) => {
        const userData = await (await getDoc(doc(firebase, "users", userObject.uid))).data(); 
        user.role = userData.role
        return userData.role

    };

    return (
      <UserContext.Provider value={{ user
                                    , logInWithEmailAndPassword
                                    , registerWithEmailAndPassword
                                    , sendPasswordReset
                                    , logout
                                    , fetchOrders
                                    , ordersObject
                                    , updateOrderStatus
                                    , addOrderToMyDataBase
                                    , handleErrors
                                    , updateOrderShippedStatus 
                                    , clientDataObject
                                    , updateData
                                     }}>
        {props.children}
      </UserContext.Provider>
    );
  };
  