import React, { useContext } from "react";
import styles from './About.module.css';
import { UserContext } from "../../context/userContext";

const About = () => {
  const userContext = useContext(UserContext);
  const { clientDataObject } = userContext;

    return (
      <div className={styles.About}>
          <h2> About</h2>
          <p>{clientDataObject.aboutText}</p>
      </div>
    );
  }  

export default About;
