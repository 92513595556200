import React from "react";
import {
  Routes as Switch,
  Route
} from "react-router-dom";
import Basket from "../../components/Basket";
import Account from "../../components/Account";
import ShopItemContainer from "../../components/ShopItemContainer";
import About from "../../components/About";
import Contact from "../../components/Contact";
import Homepage from "../../components/Homepage";
import Checkout from "../../components/Checkout";

const Routing = (props) => {

  return (
    <>
      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Switch>
          <Route path="/" element={<Homepage />} />
          <Route path="/Account" element={<Account />} />
          <Route path="/Basket" element={<Basket />} />
          <Route path="/Shop" element={<ShopItemContainer />} />
          <Route path="/About" element={<About />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Checkout" element={<Checkout />} />
          <Route path="*" element={<Homepage />} />
        </Switch>
      </>
  );
};

export default Routing;
