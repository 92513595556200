import React, { useContext } from "react";
import { PaymentContext } from "../../context/paymentContext";
import styles from "./OrderItem.module.css"
import { UserContext } from "../../context/userContext";

const OrderItem = (props) => {
  const paymentContext = useContext(PaymentContext);
  const { getRefund  } = paymentContext;
  const { orderItem , updateOrderShippedStatus , role } = props;
  const userContext = useContext(UserContext);
  const { updateOrderStatus } = userContext;

    /// handle refund 
    const handleRefund = () => {
      getRefund(orderItem.paypalOrderData.purchase_units[0].payments.captures[0].links[1].href
                ,orderItem.orderId )
      updateOrderStatus(orderItem.orderId , "REFUNDED" )
    }
    //ttogle the status of the order
    const handleStatusToggle = () => {
      if (orderItem.orderStatus === 'PAID') {
        updateOrderShippedStatus(orderItem.orderId, 'SHIPPED',role)
      } else if (orderItem.orderStatus === 'SHIPPED') {
        updateOrderShippedStatus(orderItem.orderId, 'PAID',role)
      }
    }
    // render the items
    const renderItems = (orderItem.boughtItems.items) ? orderItem.boughtItems.items.map((item,index) =>  
        <li key={index}> 
          {item.quantity} x {item.name}
        </li> 
      ) : ''

        return (
          <div className={styles.OrderItem}>
              <p>Status: <br/>{orderItem.orderStatus}</p> 
              <p>Payment Status: <br/>{orderItem.paymentStatus}</p> 
              <p >Create Time:  <br/>{orderItem.createTime}</p>            
              <p>Amount Paid: <br/> {orderItem.boughtItems.amount.value}{orderItem.boughtItems.amount.currency_code}</p>            
              <p>Paypal Fee:  <br/>{orderItem.paypalOrderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.value}
                    {orderItem.paypalOrderData.purchase_units[0].payments.captures[0].seller_receivable_breakdown.paypal_fee.currency_code}
              </p>   
              <p>Order Id:  <br/> {orderItem.orderId}</p> 
              <p>Items Bought: <br/>
                {renderItems}
              </p>
              <p>Name: <br/> {orderItem.name}</p>
              <p >Billing Address: <br/>
                {orderItem.billingAddres.streetAddress}<br/>
                {orderItem.billingAddres.extendedAddress}<br/>
                {orderItem.billingAddres.locality}<br/>
                {orderItem.billingAddres.region}<br/>
                {orderItem.billingAddres.postalCode}<br/>
                {orderItem.billingAddres.countryCodeAlpha2}<br/>
              </p>
              <p >Shipping Address: <br/>
                {orderItem.deliveryAddress.address.address_line_1} <br/>
                {orderItem.deliveryAddress.address.address_line_2}<br/>
                {orderItem.deliveryAddress.address.admin_area_1}<br/>
                {orderItem.deliveryAddress.address.admin_area_2}<br/>
                {orderItem.deliveryAddress.address.postal_code}<br/>
                {orderItem.deliveryAddress.address.country_code}<br/>
                </p>
                <p>
                  <button value="submit" onClick={handleRefund}>Refund</button>
                </p>
            {role === 'admin' ? (
            <>
                <p>
                  <button value="submit" onClick={handleStatusToggle}>Shipped</button>
                </p>
            </>
            
            ) : ('')}
          </div>
        )
  }
export default OrderItem;
