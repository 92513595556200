import React, { createContext, useState  } from "react";
import { multiply, add } from 'mathjs'
export const BasketContext = createContext({});

export const BasketProvider = (props) => {

var basketObjectInitial = [{
                              "items": [],
                              "amount": {
                                  "currency_code": "GBP",
                                  "value": 0.00,
                                  "breakdown": {
                                      "item_total": {
                                          "currency_code": "GBP",
                                          "value": 0.00
                                      }
                                  }
                            }
                          }]



const [basketObjectCount, setBasketObjectCount] = useState(0)
// disabling lint so that we can add to the baseket as an object.
// eslint-disable-next-line 
const [basketObject, setBasketObject] = useState(basketObjectInitial)

// Paypal orders have a limit on the amount of words in the descripption.
// this function will take only the first sentence
const updateBasketDescriptions = (basket, product) => {
    basket.map(item => {
      if (item.name === product.name){
      const firstSentence = item.description.indexOf('.')
        item.description = item.description.substring(0,firstSentence)
      }   return basket
    }) 
}

// payapl needs the total quantity to ba added to the baseket.
// we want to update the quantity from all the orders,
const updateBasketQuanitity = (basket, product, addOrRemove) => {
  basket.map(item => {
    if (item.name === product.name && addOrRemove === 'add'){
      item.quantity ++
    } else  if (item.name === product.name && addOrRemove === 'remove'){
      item.quantity --
    }   return basket
  }) 
  return basket
}

// payapl needs the total amount to ba added to the baseket.
// we want to update the total amount from all the orders
const updateBasketTotals = (basket) => {
  var grandTotal = 0
  basket.map(item => {
    const itemTotal = multiply(item.quantity, item.unit_amount.value)
    grandTotal = add(grandTotal,  itemTotal)
    return grandTotal
  }) 
  basketObject[0].amount.value = grandTotal
  basketObject[0].amount.breakdown.item_total.value = grandTotal
}

// we want a basket function that is ready for checkout and updates the totals 
// we also need to make the incoming product immutable so it doesnt update the original product array.
const updateBasket = (selectedProduct, method) => {

  const prodductToAdd = Object.assign({}, selectedProduct);
  const isSelectedProductInBasket = (basketObject[0].items.filter(item =>item.name === prodductToAdd.name)[0])
    if (isSelectedProductInBasket){
      updateBasketQuanitity(basketObject[0].items, selectedProduct, method)
      updateBasketTotals(basketObject[0].items)

    } else if (method === 'add' && !isSelectedProductInBasket) {
      setBasketObjectCount(basketObjectCount+1)
      basketObject[0].items = [...basketObject[0].items,prodductToAdd]
      updateBasketTotals(basketObject[0].items)

    } else  if (method === 'remove'){
      setBasketObjectCount(basketObjectCount-1)
      // eslint-disable-next-line 
      basketObject = basketObject[0].items.filter(item =>item.name !== prodductToAdd.name)
      updateBasketTotals(basketObject[0].items)
    }
    updateBasketDescriptions(basketObject[0].items, prodductToAdd)
  }

  return (
    <BasketContext.Provider value={{ updateBasket, basketObject, basketObjectCount }}>
      {props.children}
    </BasketContext.Provider>
  );
};
