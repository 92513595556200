import React, { useContext } from "react";
import styles from './Basket.module.css';
import { BasketContext } from "../../context/basketContext";
import ShopItem from "../ShopItem/ShopItem";
import { Link } from "react-router-dom";
import svgCheckout from '../../assets/attach_money_black_24dp.svg'

const Basket = (props) => {
 
  const basketContext = useContext(BasketContext);
  const { basketObject , updateBasket , basketObjectCount } = basketContext;

  const renderBasket = (basketObjectCount !== 0) ? basketObject[0].items.map((element,index) =>  
      <ShopItem 
        key={index}
        productItem={element} 
        updateBasket={updateBasket}
        isCheckout={true}/>
                    ) :
        <p>No Items in Basket</p>

  return (
   <div className={styles.Basket}>
    <h2>Basket</h2>
    <h2>Go to Checkout</h2>
    
     <Link to="/Checkout" >
      <img src={svgCheckout} alt="pay icon" />
    </Link>
    <p>Total Payable: {basketObject[0].amount.breakdown.item_total.value} {basketObject[0].amount.breakdown.item_total.currency_code}</p>

    <div className={styles.BasketItemsContainer}>
      {renderBasket}
    </div>
  </div>

  );
};

export default Basket;
