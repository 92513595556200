import React, {useState, useRef } from "react";
import styles from "./ProductItem.module.css"
import svgRemove from '../../assets/remove_black_24dp.svg'

const ProductItem = (props) => {
  // eslint-disable-next-line
  const[imageProduct, setImageProduct] = useState(null);
  // eslint-disable-next-line
  const[previewUrlProduct, setPreviewUrlProduct] = useState("");
  const fileInput = useRef(null);

  const { productItem 
    , removeProductFromDataBase
    , uploadImageDataFromStorage
    , updateData
    } = props;

  const handleRemoveProductClick = (e) => {
    e.stopPropagation();
    removeProductFromDataBase(`${productItem.id}`)
  };

  const handleUpdateProductClick = (e) => {
    const valueToUpdate = e.target.value
    const columnToUpdate = e.target.id
    updateData('products', `${productItem.id}`, columnToUpdate, valueToUpdate)
  };

//on drag
const handleOnDragOver = (e) => {
    e.preventDefault();
}
//on drop
const handleOnDrop = (e) => {
    //prevent the browser from opening the image
    e.preventDefault(); 
    e.stopPropagation(); 
    //let's grab the image file
    let imageFile = e.dataTransfer.files[0];
    handleFile(imageFile);
} 

// save the file and update the image name to be the one we are dopping
const handleFile = (file) => {
  //you can carry out any file validations here...
  setImageProduct(file);
  setPreviewUrlProduct(URL.createObjectURL(file));
  uploadImageDataFromStorage(file.name,file,"products", `${productItem.id}`, 'imageURL',updateData)
}

  return (
    <div className={styles.ProductItem}>
        <img src={svgRemove}  className={styles.imgIcon} alt='remove' onClick={handleRemoveProductClick} />
        <input id='name' defaultValue={productItem.name} onChange={handleUpdateProductClick}/>
        <input id='imageURL' defaultValue={productItem.imageURL} onChange={handleUpdateProductClick}/>
        <input type={'number'} id='unit_amount.value' defaultValue={productItem.unit_amount.value} onChange={handleUpdateProductClick}/>
        <textarea id='description' defaultValue={productItem.description} onChange={handleUpdateProductClick}/>
          <img 
            id={productItem.imageLink}
            className={styles.drop_zone}
            onDragOver = {handleOnDragOver}
            onDrop = {handleOnDrop}
            onClick = { () => fileInput.current.click()}
            src={productItem.imageURL}
            alt="Picure of Product" />
        <input 
           type="file" 
           accept='image/*' 
           ref={fileInput} hidden 
           onChange={e => handleFile(e.target.files[0])}
          />
    </div>
    );
  }

export default ProductItem;
