import React, { useContext } from "react";
import styles from './ShopItemContainer.module.css';
import ShopItem from "../ShopItem/ShopItem"
import { ProductContext } from "../../context/productContext";
import { BasketContext } from "../../context/basketContext";

const ShopItemContainer = (props) => {

  const basketContext = useContext(BasketContext);
  const { updateBasket } = basketContext;

  const productContext = useContext(ProductContext);
  const { productsObject ,  downloadImageDataFromStorage } = productContext;

  const renderProducts = productsObject.length ? productsObject.map((item,index) =>
    <ShopItem 
              key={index}
              downloadImageDataFromStorage={downloadImageDataFromStorage}
              productItem={item} 
              updateBasket={updateBasket}
              isCheckout={false}/>

    ) : ''

  return (
    <div className={styles.ShopItemContainer}>
        {renderProducts}
    </div>
  );
};

export default ShopItemContainer;
