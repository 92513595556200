import React, { useContext , useState , useRef} from "react";
import styles from './Account.module.css';
import { UserContext } from "../../context/userContext";
import { ProductContext } from "../../context/productContext";
import AccountLoginOrRegister from "../AccountLoginOrRegister";
import OrderItem from "../OrderItem/OrderItem";
import ProductItem from "../ProductItem";
import svgAdd from '../../assets/add_black_24dp.svg'
import Modal from '../Modal'

const Account = () => {
  const userContext = useContext(UserContext);
  const productContext = useContext(ProductContext);
  const { user 
        , ordersObject
        , logout
        , updateOrderShippedStatus
        , clientDataObject
        , updateData } = userContext;
  const { productsObject
        , addProductToDataBase 
        , removeProductFromDataBase
        , uploadImageDataFromStorage 
        , isLoading } = productContext;

  const [hasOrderHidden , setHasOrderHidden] = useState(false)
  const [hasProductsHidden , setHasProductsHidden] = useState(false)
  const [hasAboutHidden , setHasAboutHidden] = useState(false)
    // eslint-disable-next-line
  const[image, setImage] = useState(null);
    // eslint-disable-next-line
  const[previewUrl, setPreviewUrl] = useState("");

  const fileInput = useRef(null);
  const IconInput = useRef(null);

  const handleLogoutClick = () => {
    logout()
  }
  const handleProductsClick = () => {
    setHasProductsHidden(true)
    setHasOrderHidden(false)
    setHasAboutHidden(false)
  }
  const handleOrdersClick = () => {
    setHasProductsHidden(false)
    setHasOrderHidden(true)
    setHasAboutHidden(false)
  }
  const handleAboutClick = () => {
    setHasProductsHidden(false)
    setHasOrderHidden(false)
    setHasAboutHidden(true)
  }
  const handleAddProductClick = (e) => {
    e.stopPropagation();
    addProductToDataBase()
  };
  const handleUpdateAboutInput = (e) => {
    const valueToUpdate = e.target.value
    const columnToUpdate = e.target.id
    updateData('clientInformation', 'clientInformation', columnToUpdate, valueToUpdate)
  };
  //on onMouseLeave
  const handleMouseLeave = (e) => {
    console.log('leave')
    e.preventDefault();
    e.stopPropagation(); 
  }
  //on drag
  const handleOnDragOver = (e) => {
    console.log(e.target.style.transform)
    e.target.style.transform = 'rotate(10deg)';
    e.preventDefault();
  }
  //on drop
  const handleOnDrop = (e) => {
    //prevent the browser from opening the image
    e.preventDefault(); 
    e.stopPropagation(); 
    //let's grab the image file
    let imageFile = e.dataTransfer.files[0];
    const imageId = e.target.id;
    e.target.style.transform = 'rotate(-10deg)';
    handleFile(imageFile, imageId);
  } 

  // save the file and update the image name to be the one we are dopping
  const handleFile = (file , imageId) => {
    //you can carry out any file validations here...
    setImage(file);
    setPreviewUrl(URL.createObjectURL(file));
    uploadImageDataFromStorage(file.name,file, 'clientInformation', 'clientInformation',imageId,updateData)
}

  //if they are logged in show accout otherwise login
  if (user == null) {  
    return (<AccountLoginOrRegister/>)
  } 
  else if (user && ordersObject){
    const renderOrders = (ordersObject) ? ordersObject.map((element,index) =>  
    <OrderItem 
      key={index}
      orderItem={element}
      updateOrderShippedStatus={updateOrderShippedStatus}
      role={user.role}
      />
      ) : 'No Items in Basket'
      const renderProducts = (productsObject) ? productsObject.map((element,index) =>  
      <ProductItem 
        productItem={element}
        key={index}
        addProductToDataBase={addProductToDataBase}
        removeProductFromDataBase={removeProductFromDataBase}
        uploadImageDataFromStorage={uploadImageDataFromStorage}
        updateData={updateData}
        />
        ) : ''
        
      const stylesOrders = (hasOrderHidden) ? styles.show : styles.hide
      const stylesProducts = (hasProductsHidden) ? styles.productShow : styles.hide
      const stylesAbout = (hasAboutHidden) ? styles.show : styles.hide
      
      const Loading = (isLoading) ? (<Modal/>) : ''

    return (
      <div className={styles.Account}>
        <div className={styles.AccountHeader}>
          <button onClick={handleLogoutClick}>Logout</button>
          <button onClick={handleOrdersClick}>Orders</button>
          {user.role === 'admin' ? (
            <>
            <button onClick={handleProductsClick}>Products</button>
            <button onClick={handleAboutClick}>About</button>
            </>
          ) : ('')
          }        
        </div>
        <h2>My Account</h2>
        <p>Welcome Back,  you are a {user.role}</p>
        <p>Use this area to track and manage your orders</p>
        <div className={`${stylesOrders}`}>
          <h3>Your Orders</h3> 
          {renderOrders}
        </div>
        <div className={`${stylesAbout}`}>
          <h3>About</h3> 
          <p>Homepage Picture</p>
            <div className={styles.drop_zone}>
              {Loading}
              <img 
                id={'landingPageImageURL'}
                onDragOver = {handleOnDragOver}
                onDrop = {handleOnDrop}
                onMouseLeave = {handleMouseLeave}
                onClick = { () => fileInput.current.click()}
                src={clientDataObject.landingPageImageURL}
                alt="Homepage picure if the product being sold" />
              <input 
                type="file" 
                accept='image/*' 
                ref={fileInput} hidden 
                onChange={e => handleFile(e.target.files[0])}
              />
          </div>
          <p>Icon Picture</p>
          <div className={styles.drop_zone}>
              <img 
                  id={'iconURL'}
                  onDragOver = {handleOnDragOver}
                  onDrop = {handleOnDrop}
                  onMouseLeave = {handleMouseLeave}
                  onClick = { () => fileInput.current.click()}
                  src={clientDataObject.iconURL}
                  alt="icon picure if the product being sold" />
              <input 
                type="file" 
                accept='image/*' 
                ref={IconInput} hidden 
                onChange={e => handleFile(e.target.files[0])}
                />
          </div>
          <input id='nameOfCompany' placeholder="Company Name" defaultValue={clientDataObject.nameOfCompany} onChange={handleUpdateAboutInput}/>
          <input id='iconURL' placeholder="Icon URL" defaultValue={clientDataObject.iconURL} onChange={handleUpdateAboutInput}/>
          <input id='landingPageImageURL' placeholder="Image URL" defaultValue={clientDataObject.landingPageImageURL} onChange={handleUpdateAboutInput}/>
          <textarea id='aboutText' placeholder="About Text" defaultValue={clientDataObject.aboutText} onChange={handleUpdateAboutInput}/>
          <textarea id='contactText'vplaceholder="Contact Text" defaultValue={clientDataObject.contactText} onChange={handleUpdateAboutInput}/>

        </div>
        <div className={`${stylesProducts}`}>
          <h3>Your Products</h3>
          <img src={svgAdd} className={styles.imgIcon} alt='add' onClick={handleAddProductClick} /> 
          <p>
            Edit Product , edit to update . the images is drag and drop
          </p>
          {renderProducts}
        </div>
      </div>
    );
  }  
};

export default Account;
