import React, { useContext , useEffect } from "react";
import styles from './NavigationBar.module.css';
import { Link,  BrowserRouter as Router } from "react-router-dom";
import Routing from '../../containers/Routing'
import svgShoppingBasket from '../../assets/shopping_basket_black_24dp.svg'
import { BasketContext } from "../../context/basketContext";
import { UserContext } from "../../context/userContext";
import ReactGA from 'react-ga';

ReactGA.initialize({ trackingId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID });

const NavigationBar = (props) => {
  const userContext = useContext(UserContext);
  const { clientDataObject } = userContext;
  const basketContext = useContext(BasketContext);
  const { basketObjectCount  } = basketContext;
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
  
  <Router>
    <header>
      <div className={styles.AppHeadingTitleContainer}>
        <h1 className={styles.AppHeadingTitle}>{clientDataObject.nameOfCompany}</h1>
          <Link to="/Basket">
            <p id="basketObjectCount">{basketObjectCount}</p>
            <img src={svgShoppingBasket} alt="shopping basket icon" />
          </Link>
      </div>
      <nav className={styles.NavigationBar}>
        <li>
          <Link to="/">Home</Link>
        </li>
        <li>
          <Link to="/Account">Account</Link>
        </li>
        <li>
          <Link to="/Shop">Shop</Link>
        </li>
        <li>
          <Link to="/About">About</Link>
        </li>
        <li>
          <Link to="/Contact">Contact</Link>
        </li>
    </nav>
    <div>
      <Routing/>
    </div>
  </header>

  </Router>

  );
}

export default NavigationBar;
