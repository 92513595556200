import React from "react";
import styles from './ShopItem.module.css';
import svgAdd from '../../assets/add_black_24dp.svg'
import svgRemove from '../../assets/remove_black_24dp.svg'

const ShopItem = (props) => {

  const { productItem 
        , updateBasket 
        , isCheckout} = props;

  const handleAddClick = (e) => {
    e.stopPropagation();
    updateBasket(productItem,'add');
  };

  const handleRemoveClick = (e) => {
    e.stopPropagation();
    updateBasket(productItem,'remove');
  };
  const stylesDynamic = (isCheckout) ? styles.ShopItemCheckout : styles.ShopItem
  return (
    <div className={`${stylesDynamic}`}>
      <h2 className={styles.productItemName}>{productItem.name}</h2>
      <img id={productItem.imageLink} src={productItem.imageURL} className={styles.ProductImage} alt="Picure of Product" />
      <div className={styles.productItemPrice}>
        <p>Price: {productItem.unit_amount.value} : {productItem.unit_amount.currency_code}</p>
      </div>
      <div className={styles.iconContainer}>
        <img src={svgAdd} className={styles.imgIcon} alt='add' onClick={handleAddClick} />
        <img src={svgRemove} className={styles.imgIcon}  alt='remove' onClick={handleRemoveClick} />
      </div>
      <p className={styles.productItemDescription}>{productItem.description}</p>

     </div>
  );
};

export default ShopItem;
