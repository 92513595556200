import React, { useContext, useState, useEffect } from "react";
import styles from './Checkout.module.css';
import { BasketContext } from "../../context/basketContext";
import { PaymentContext } from "../../context/paymentContext";
import { UserContext } from "../../context/userContext";
import { loadScript } from "@paypal/paypal-js";
import Modal from './../Modal/Modal'
import AccountLoginOrRegister from "../AccountLoginOrRegister";
import { Navigate } from 'react-router-dom';

var error = false

const Checkout = () => {
  const userContext = useContext(UserContext);
  const { user, addOrderToMyDataBase, handleErrors, fetchOrders } = userContext;
  const basketContext = useContext(BasketContext);
  const { basketObject , basketObjectCount } = basketContext;
  const paymentContext = useContext(PaymentContext);
  const { clientToken , createPaypalOrder ,completePaypalOrder} = paymentContext;  
  const [isLoading , setIsLoading] = useState(false)
  const [hasShippingAddress , setHasShippingAddress] = useState(false)
  const [isReadyForRedirect , setIsReadyForRedirect ] = useState(false)

  var shipping ;
  var checkoutBasketObject = [];
  // hide delivery address if needed
  const stylesDelivery = (hasShippingAddress) ? styles.deliveryShow : styles.deliveryHide

  //control hiding of shipping fields
  const toggleShiping = () => {
    if (hasShippingAddress === false){
      setHasShippingAddress(true)
    } else if (hasShippingAddress === true){
      setHasShippingAddress(false)
    }
  }
  // hosted fields has inbuilt validation, lets hook into that 
  // by chcking for invalid class in the inputs
  const validateForm = () => {
      document.querySelectorAll("[class^='Checkout_card_field__']").forEach(item => {
        if (item.className.includes('invalid')) {
          document.getElementById("errorTextPayments").innerText = 'Error: Invalid Card Deets'
          error = true
        }
     })
  }

// this function will wither use the billing or shipiing address if the values are filled at checkout
function shippingAddress(){
  if (document.getElementById("card-delivery-address-street").value) {
    const shipping = {
      address: {
          address_line_1: document.getElementById("card-delivery-address-street").value,
          address_line_2: document.getElementById("card-delivery-address-unit").value,
          admin_area_1: document.getElementById("card-delivery-address-state").value,
          admin_area_2: document.getElementById("card-delivery-address-city").value,
          postal_code: document.getElementById("card-delivery-address-zip").value,
          country_code: "GB"
        }
    }
    return shipping
    } else {
      const shipping = {        
        address: {
          address_line_1: document.getElementById("card-billing-address-street").value,
          address_line_2: document.getElementById("card-billing-address-unit").value,
          admin_area_1: document.getElementById("card-billing-address-state").value,
          admin_area_2: document.getElementById("card-billing-address-city").value,
          postal_code: document.getElementById("card-billing-address-zip").value,
          country_code: "GB"
      }    
    }
    return shipping
  }
}
function redirectToggle(){
  setIsReadyForRedirect(true)
}

// Renders card fields and sets up the paypal sdk
// after this has loaded we set up the palyal checkout
// define the  required parameters
// update the shopping basket.
// this code is coped from the payal advanced integration docs. 
// it makes 2 posts to the REST API, one for the order and the other to capture payment.
// some validation is erformed on the card if entered.

useEffect(() => {
  loadScript({
    "client-id": process.env.REACT_APP_CLIENT_ID ,
    "data-client-token": clientToken,
    "components" : "buttons,hosted-fields",
    "currency": "GBP"
  }).then((paypal) => {
    if (paypal.HostedFields.isEligible() && process.env.REACT_APP_CLIENT_ID && clientToken) {
      var orderId;
      paypal.HostedFields.render({
        // Call your server to set up the transaction
        createOrder: () => {
          //eslint-disable-next-line
          shipping = shippingAddress(hasShippingAddress)
          //eslint-disable-next-line
          checkoutBasketObject = {...basketObject[0],shipping}
          return createPaypalOrder(checkoutBasketObject, setIsLoading ,handleErrors )
          .then(result => {orderId = result
            console.log(orderId)
            return orderId
          })

        },
        styles: {
          '.valid': {
            'color': 'green'
          },
          '.invalid': {
            'color': 'red'
          }
        },
        fields: {
          number: {
            selector: "#card-number",
            placeholder: "4111 1111 1111 1111"
          },
          cvv: {
            selector: "#cvv",
            placeholder: "123"
          },
          expirationDate: {
            selector: "#expiration-date",
            placeholder: "MM/YY"
          }
        }
    }).catch((err) => {
      return
    })
      .then((cardFields) => {
        document.querySelector("#card-form").addEventListener("submit", (event) => {
          const cardholderName = document.getElementById("card-holder-name").value
          const billingAddress = {
              streetAddress: document.getElementById("card-billing-address-street").value,
              extendedAddress: document.getElementById("card-billing-address-unit").value,
              region: document.getElementById("card-billing-address-state").value,
              locality: document.getElementById("card-billing-address-city").value,
              postalCode: document.getElementById("card-billing-address-zip").value,
              countryCodeAlpha2: "GB",
            }
          validateForm()
          if (error === true)
            return;
          event.preventDefault();
          cardFields.submit({
              cardholderName: cardholderName,
              billingAddress: billingAddress

            }).catch((err) => {
              console.log(err)
              handleErrors(err.details[0].description, "errorTextPayments")
            })
            .then(async() => {
                await completePaypalOrder( 
                  setIsLoading, handleErrors, addOrderToMyDataBase
                  , checkoutBasketObject, cardholderName, billingAddress
                  , shipping, user.email , orderId, fetchOrders, user)
                  redirectToggle()
            })
            .catch((err) => {
              console.log(err)

              //handleErrors(err.details[0].description, "errorTextPayments")
              setIsLoading(false)
            })
        })
      })
     } else  if (!paypal.HostedFields.isEligible() || basketObjectCount === 0) {
        // Hides card fields if the merchant isn't eligible
      //document.querySelector("#card-form").style = 'display: none';
    }
  })
}, [user]);


    //if basket empty show warning
    if (basketObjectCount === 0){
      return (<p>Your Trolley is Empty</p>)
    }
    //if they are logged in show checkout otherwise login page
    if (user == null) {  
      return (<AccountLoginOrRegister/>)
    } 
    // if done redirect
    if (isReadyForRedirect) {  
      return (<Navigate replace to="/Account" />)
    } 
    if (!isLoading) {
      return (
        <div className={styles.Checkout}>
          <h2>Checkout</h2>
            <p>Total Payable: {basketObject[0].amount.breakdown.item_total.value} {basketObject[0].amount.breakdown.item_total.currency_code}</p>
            <form id="card-form">
               <div className={styles.address}>
                <p id="errorTextPayments" className={styles.errorText}> </p>
                <div id="card-number" className={styles.card_field}></div>
                <div id="expiration-date" className={styles.card_field}></div>
                <div id="cvv" className={styles.card_field}></div>            
                <input type="text" id="card-holder-name" name="card-holder-name" autoComplete="off" placeholder="card holder name"/>
                <input type="text" id="card-billing-address-street" name="card-billing-address-street" autoComplete="off" placeholder="street address"/>
                <input type="text" id="card-billing-address-unit" name="card-billing-address-unit" autoComplete="off" placeholder="unit"/>
                <input type="text" id="card-billing-address-city" name="card-billing-address-city" autoComplete="off" placeholder="city"/>
                <input type="text" id="card-billing-address-state" name="card-billing-address-state" autoComplete="off" placeholder="state"/>
                <input type="text" id="card-billing-address-zip" name="card-billing-address-zip" autoComplete="off" placeholder="zip / postal code"/>
                <div className={styles.CheckBoxContainer}>
                   <p>Ship to different Address?</p>
                    <input type="checkbox" id="billingAndShippingAddressSame" onClick={toggleShiping}/>  
                </div>
              </div>
              <div className={`${stylesDelivery}`}>
                <input type="text" id="card-delivery-address-street" name="card-delivery-address-street" autoComplete="off" placeholder="street address"/>
                <input type="text" id="card-delivery-address-unit" name="card-delivery-address-unit" autoComplete="off" placeholder="unit"/>
                <input type="text" id="card-delivery-address-city" name="card-delivery-address-city" autoComplete="off" placeholder="city"/>
                <input type="text" id="card-delivery-address-state" name="card-delivery-address-state" autoComplete="off" placeholder="state"/>
                <input type="text" id="card-delivery-address-zip" name="card-delivery-address-zip" autoComplete="off" placeholder="zip / postal code"/>
              </div>
             <button id='pay'>Pay</button>
            </form>
        </div>
      ) 
    } else {
    return (<Modal/>)
  } 

}

export default Checkout;
