import React, { useContext } from "react";
import styles from './Homepage.module.css';
import { UserContext } from "../../context/userContext";

const Homepage = () => {
  const userContext = useContext(UserContext);
  const { clientDataObject } = userContext;  
  
 return (
    <div className={styles.Homepage}>
      <div className={styles.HomePictureContainer}>
        <img className={styles.HomePicture} src={clientDataObject.landingPageImageURL} alt="Home" />
      </div>
    </div> 
  );
};

export default Homepage;
