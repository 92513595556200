import React  from 'react';
import NavigationBar from './components/NavigationBar/';
import { ProductProvider } from "./context/productContext";
import { BasketProvider } from "./context/basketContext";
import { PaymentProvider } from "./context/paymentContext";
import { UserProvider } from "./context/userContext";

function App() {
  return (
  <PaymentProvider>
      <ProductProvider>
       <UserProvider>    
        <BasketProvider>    
          <NavigationBar/>
        </BasketProvider>
       </UserProvider>
      </ProductProvider>    
    </PaymentProvider>
  );

}
export default App;
